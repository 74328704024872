import React from 'react'
import styled from 'styled-components'

const MessageStyle = styled.div`
  padding:var(--s0);
    
  &.success {
    background:var(--color-success-background);
    color:var(--color-success);
    border:var(--border-thin) solid var(--color-success-border);
  }
  &.error {
    background:var(--color-error-background);
    color:var(--color-error);
    border:var(--border-thin) solid var(--color-error-border);
  }
  &.info {
    background:var(--color-info-background);
    color:var(--color-info);
    border:var(--border-thin) solid var(--color-info-border);
  }
  &.warning {
    background:var(--color-warning-background);
    color:var(--color-warning);
    border:var(--border-thin) solid var(--color-warning-border);
  }
  &.space-below {
      margin-bottom: ${props => (props.margin ? props.margin : 'var(--s4)')};
  }
`

const Message = props => {
    let messageDisplay = ''

    if(props.display) {
        messageDisplay = (
            <MessageStyle className={props.className}>
                <p>{props.message}</p>
            </MessageStyle>
        )
    }

    return(
        <div id={props.messageId} tabIndex="-1">
            {messageDisplay}
        </div>
    )
}

export default Message