import React from 'react'
import styled from 'styled-components'

const PlusIcon = props => {

	return (
		<svg viewBox="0 0 32 32" focusable="false" role={props.role ? props.role : 'img'} height="24px" width="24px" aria-labelledby={props.svgId} className={props.className}>
			<title id={props.svgId}>{props.title ? props.title : 'Plus'}</title>
			<path d="M16,1.7C8.1,1.7,1.7,8.1,1.7,16S8.1,30.3,16,30.3S30.3,23.9,30.3,16S23.9,1.7,16,1.7z M16,0c8.8,0,16,7.2,16,16
	s-7.2,16-16,16S0,24.8,0,16S7.2,0,16,0 M8.4,15.2h6.7V8.4h1.7v6.7h6.7v1.7h-6.7v6.7h-1.7v-6.7H8.4V15.2"/>
		</svg>
	)
}

const PlusIconStyle = styled(PlusIcon)`
	fill: ${props => (props.fill ? props.fill : 'currentColor')};
	width: ${props => (props.width ? props.width : 'var(--s0)')};
	height: ${props => (props.height ? props.height : 'var(--s0)')};
    position:relative;
    top:var(--border-thin);
`

export default PlusIconStyle
