import React from 'react'
import styled from 'styled-components'

const Box = props => (
	<div className={props.className}>
		{props.children}
	</div>
)

const BoxStyle = styled(Box)`
  margin: ${props => (props.margin ? props.margin : '0')};
  padding: ${props => (props.padding ? props.padding : '0')};
  border: ${props => (props.border ? props.border : 'none')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  background: ${props => (props.background ? props.background : 'none')};
  color: ${props => (props.color ? props.color : '')};

  .font-small {
	var(--font-size-small);
  }
`

export default BoxStyle
