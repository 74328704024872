import React from 'react'
import styled from 'styled-components'

const Sidebar = props => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
}

const SidebarContainer = styled(Sidebar)`     
    & > * {
      display: flex;
      flex-wrap: wrap;
      gap: ${props => props.marginCalculation};
    }
    
    & > * > * {
      flex-basis: ${props => props.flexBasis};
      flex-grow: 1;
    }
    
    & > * > ${props => (props.side ? ':first-child' : ':last-child')} {
      flex-basis: 0;
      flex-grow: 999;
      min-width: ${props => props.lastChildWidth};
  
`

export default SidebarContainer