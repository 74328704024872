import React from 'react'
import styled from 'styled-components'
import {GatsbyImage} from "gatsby-plugin-image"

const ImageContainer = props => (
	<GatsbyImage className={props.className} alt={props.alt} image={props.image} />
)

const ImageContainerStyle = styled(ImageContainer)`
  border:var(--layout-space-support) solid var(--color-background-light-primary);
  @supports (border:var(--layout-space) solid var(--color-background-light-primary)) {
    border:var(--layout-space) solid var(--color-background-light-primary);
  }
  background:var(--color-background-light-primary);
`

export default ImageContainerStyle