import React from 'react'
import styled from 'styled-components'
import {ButtonIcon} from "../Button/Button";
import MinusIcon from "../Icons/MinusIcon";
import PlusIcon from "../Icons/PlusIcon";
import Cluster from "../Cluster/Cluster";

const NumberInput = props => {
	return (
		<Cluster className={props.className} spacing="var(--layout-space)">
			<div>
				<ButtonIcon className="minus" onClick={props.changeMinus} disabled={props.value === 1 || props.loadingState ? 'disabled' : ''}>
					<MinusIcon width="var(--s0x2)" height="var(--s0x2)" fill="var(--color-primary)" title={`Minus 1 item: ${props.labelText}`} svgId={`minus-1-${props.id}`} />
				</ButtonIcon>
				<InputStyle
					inputmode="numeric"
					pattern="[0-9]*"
					type="text"
					className={props.errorClass}
					value={props.value}
					onChange={props.changed}
					id={props.id}
					disabled={props.loadingState}
				/>
				<ButtonIcon className="plus" onClick={props.changePlus} disabled={props.loadingState}>
					<PlusIcon width="var(--s0x2)" height="var(--s0x2)" fill="var(--color-primary)" title={`Add 1 item: ${props.labelText}`} svgId={`plus-1-${props.id}`} />
				</ButtonIcon>
			</div>
		</Cluster>
	)
}

const NumberInputStyle = styled(NumberInput)`

  `

const InputStyle = styled.input`
  border:var(--border-thinnest) solid var(--color-border-dark);
  padding:var(--s-4) var(--s-2);
  border-radius:var(--border-radius);
  width:var(--form-width-tiny);
  max-width:var(--form-width);
  background: var(--color-background-light-primary);
  
  &.error {
    border:var(--border-thinnest) solid var(--color-error-border);
  }
`
export default NumberInputStyle