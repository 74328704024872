import React from 'react'
import styled from 'styled-components'
import StackSmallest from '../Stack/StackSmallest'
import StackSmall from '../Stack/StackSmall'
import AlertIcon from '../Icons/AlertIcon'
import NumberInput from "./NumberInput"

const Input = props => {
    let inputElement = null
    let errorControl

    if (props.invalid && props.shouldValidate && props.formSubmitted) {
        errorControl = 'error'
    }

    switch ( props.elementType ) {
        case ( 'input' ):
            inputElement = <TextInputStyle
                className={errorControl}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />
            break;
        case ( 'textarea' ):
            inputElement = <TextareaStyle
                className={errorControl}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />
            break;
        case ( 'select' ):
            const { ...selectProps } = props.elementConfig
            delete selectProps['options']

            inputElement = (
                <SelectStyle
                    {...selectProps}
                    className={errorControl}
                    value={props.value}
                    onChange={props.changed}>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </SelectStyle>
            );
            break;
        case ( 'radio' ):

            let { ...radioProps } = props.elementConfig;
            delete radioProps['aria-describedby']
            delete radioProps['options']

            inputElement = props.radioType === 'push' ? (
                <StackSmall>
                    <PushRadioStyle>
                        {props.elementConfig.options.map((option, index) => (
                                <label key={`${index}-${props.elementConfig.id}`} htmlFor={`${index}-radio-${props.elementConfig.id}`}>
                                    <input
                                        {...radioProps}
                                        id={`${index}-radio-${props.elementConfig.id}`}
                                        aria-describedby={`${props.elementConfig['aria-describedby']} ${props.elementConfig['id']}`}
                                        value={option.value}
                                        onChange={props.changed}
                                        checked={props.value === option.value ? 'checked' : ''}
                                    />
                                    <span>{option.displayValue}</span>
                                </label>

                            )
                        )}
                    </PushRadioStyle>
                </StackSmall>
            ) : (
                <StackSmall>
                    {props.elementConfig.options.map((option, index) => (
                            <RadioStyle key={`${index}-${props.elementConfig.id}`}>
                                <input
                                    {...radioProps}
                                    id={`${index}-radio-${props.elementConfig.id}`}
                                    aria-describedby={`${props.elementConfig['aria-describedby']} ${props.elementConfig['id']}`}
                                    value={option.value}
                                    onChange={props.changed}
                                    checked={props.value === option.value ? 'checked' : ''}
                                />
                                <label htmlFor={`${index}-radio-${props.elementConfig.id}`}>
                                    {option.displayValue}
                                </label>
                            </RadioStyle>
                        )
                    )}
                </StackSmall>
            )
            break;
        case ( 'checkbox' ):

            let { ...checkboxProps } = props.elementConfig
            delete checkboxProps['aria-describedby']
            delete checkboxProps['options']

            inputElement = <StackSmall>
                    {props.elementConfig.options.map((option, index) => (
                            <CheckboxStyle key={`${index}-${props.elementConfig.id}`}>
                                <input
                                    {...checkboxProps}
                                    id={`${index}-checkbox-${props.elementConfig.id}`}
                                    aria-describedby={`${props.elementConfig['aria-describedby']} ${props.elementConfig['id']}`}
                                    value={option.value}
                                    onChange={props.changed}
                                    checked={props.value === option.value ? 'checked' : ''}
                                />
                                <label htmlFor={`${index}-checkbox-${props.elementConfig.id}`}>
                                    {option.displayValue}
                                </label>
                            </CheckboxStyle>
                        ))}
                </StackSmall>
            break;
        case ( 'number' ):
            inputElement = (
                <NumberInput
                    inputmode="numeric"
                    pattern="[0-9]*"
                    errorClass={errorControl}
                    id={props.elementConfig.id}
                    value={props.value}
                    changed={props.changed}
                    changeMinus={props.changeMinus}
                    changePlus={props.changePlus}
                    loadingState={props.loadingState}
                    labelText={props.labelText} />
            )
            break;
        default:
            inputElement = <TextInputStyle
                className={errorControl}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />
    }

    let subFieldSupport = ''
    if(props.subFieldSupport) subFieldSupport = <div id={props.subFieldSupport.id}>{props.subFieldSupport.text}</div>

    const hideRequired = props.hideRequired ? '' : (<span aria-hidden="true">*</span>)
    let requiredText = ''
    requiredText = props.notRequired === true ? '' : <span title="required field"> {hideRequired} <span className="vh"> required field</span></span>

    let validationError = null;
    if (props.invalid && props.formSubmitted && props.elementConfig['aria-describedby']) {
        validationError = <ValidationStyle id={props.elementConfig['aria-describedby']}>
            <AlertIcon id={'icon-' + props.elementConfig['aria-describedby']} role="presentation" fill="var(--color-error)" ariaHidden />
            <strong>{props.errorMessage}</strong>
        </ValidationStyle>
    }

    let labelElement = (
        <>
            {props.elementType === 'radio' || props.elementType === 'checkbox' ?
            (<LabelStyle as="strong" id={props.elementConfig['id']} className={props.visibleLabel ? 'vh' : ''}>{props.labelText} {requiredText}</LabelStyle>) :
            (<LabelStyle htmlFor={props.elementConfig.id} className={props.visibleLabel ? 'vh' : ''}>{props.labelText} {requiredText}</LabelStyle>)}
        </>
    )

    return (
        <StackSmallest className={`${props.className} ${props.elementConfig.id} ${props.firstMargin ? 'first-margin' : ''}`}>
            {labelElement}
            {validationError}
            {inputElement}
            {subFieldSupport}
        </StackSmallest>
    )

}

const InputStyle = styled(Input)`
    &&.label-normal label, &&.label-normal strong {
        font-weight:var(--font-weight-normal);
    }
`

const LabelStyle = styled.label`
    display:block;
    font-weight: ${props => (props.labelElement ? props.labelElement : 'var(--font-weight-medium)')};
`

const TextInputStyle = styled.input`
  border:var(--border-thinnest) solid var(--color-border-dark);
  padding:var(--s-4) var(--s-2);
  border-radius:var(--border-radius);
  width:100%;
  max-width:var(--form-width);
  background: var(--color-background-light-primary);
  
  &.error {
    border:var(--border-thinnest) solid var(--color-error-border);
  }
`

const SelectStyle = styled.select`
    border:var(--border-thinnest) solid var(--color-border-dark);
    border-radius:var(--border-radius);
    width:100%;
    max-width:var(--form-width);
    background: var(--color-background-light-primary);
    appearance: none;
    padding:var(--s-4) var(--s0x2) var(--s-4) var(--s-2);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8'%3E%3Cpath fill='%23555' d='M0 0l7 8 7-8z' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;

  
  &.error {
    border:var(--border-thinnest) solid var(--color-error-border);
  }
`

const TextareaStyle = styled.textarea`
  border:var(--border-thinnest) solid var(--color-border-dark);
  padding:var(--s-4) var(--s-2);
  border-radius:var(--border-radius);
  width:100%;
  max-width:var(--form-width-largest);
  min-height:calc(9*var(--s0));
    background: var(--color-background-light-primary);
  
  &.error {
    border:var(--border-thinnest) solid var(--color-error-border);
  }
`

const RadioStyle = styled.div`
    position: relative;
    min-height: calc(1.5*var(--s0));
    
    input {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }
    
    label {
        cursor: pointer;
        padding-left: var(--s4);
        display: inline-flex;
        align-items: center;
        min-height: calc(1.5*var(--s0));
    
    }
    
    label::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(1.5*var(--s0));
        height: calc(1.5*var(--s0));
        background-image: ${`url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11.5' stroke='%234E478D' /%3E%3C/svg%3E%0A")`};
    }
    
    label:hover::before {
        background-image: ${`url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11.5' stroke='%233f33a8' /%3E%3C/svg%3E%0A")`};
    }
    
    input:checked + label::before {
        background-image: ${`url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11.5' fill='%233f33a8' stroke='%233f33a8'/%3E%3Ccircle cx='12' cy='12' r='4.5' fill='white'/%3E%3C/svg%3E")`};
    }

    input:focus + label::before {
        outline: .125rem solid #3f33a8;
        outline-offset: .125rem;
    }

    input:focus:not(:focus-visible) + label::before {
        outline: none;
    }

    input:focus-visible + label::before {
        outline: .125rem solid #3f33a8;
    }
`

const PushRadioStyle = styled.div`
        display: inline-flex;

    label {
        position: relative;
        cursor: pointer;
        align-items: center;
    }

    input {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    input + span {
        display: flex;
        border: var(--border-thinnest) solid var(--color-border-dark);
        padding: var(--s-4) var(--s-2);
        color: var(--color-text-dark);
        background: var(--color-background-light-primary);
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius);
        position: relative;
    }

    label:not(:first-of-type) input + span {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    label:not(:last-of-type) input + span {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    input:checked + span {
        background: var(--color-tertiary);
        color:var(--color-text-light);
        transition: color 0.35s ease, border 0.35s ease, background 0.35s ease;
        font-weight:var(--font-weight-medium);
    }

    input:hover + span {
        background:var(--color-background-light-secondary);
        transition: border 0.35s ease;
        color:var(--color-text-dark);
    }

    input:checked:hover + span {
        color: var(--color-text-light);
        background: var(--color-tertiary);

    }

    input:focus + span {
        outline: var(--border-thin) solid var(--color-focus);
        outline-offset: var(--border-thin);
        z-index: var(--z-index-10);
    }

    input:focus:not(:focus-visible) + span {
        outline: none;
    }

    input:focus-visible + span {
        outline: var(--border-thin) solid var(--color-focus);
        z-index: var(--z-index-10);
    }
`

const CheckboxStyle = styled.div`
        position: relative;
        min-height: var(--s0x1-5);

    input {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    label {
        cursor: pointer;
        padding-left: calc(2.5*var(--s0));
        display: inline-flex;
        align-items: center;
        min-height: var(--s0x1-5);

    }

    label::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: var(--s0x1-5);
        height: var(--s0x1-5);
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='1.5' stroke='%234E478D'/%3E%3C/svg%3E%0A");
    }

    label:hover::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='1.5' stroke='%233f33a8'/%3E%3C/svg%3E%0A");
    }

    input:checked + label::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='1.5' fill='%233f33a8' stroke='%233f33a8'/%3E%3Cpath d='M19.5 6L10.5 18.0044L4.5 12' stroke='white' stroke-width='2.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");
    }

    input:focus + label::before {
        outline: var(--border-thin) solid var(--color-primary);
        outline-offset: var(--border-thin);
    }

    input:focus:not(:focus-visible) + label::before {
        outline: none;
    }

    input:focus-visible + label::before {
        outline: var(--border-thin) solid var(--color-primary);
    }
`

const ValidationStyle = styled.p`
  color:var(--color-error);
    display:inline-flex;
    align-items:center;
    
    svg + strong {
        margin-left:var(--s-3);
    }
`

export default InputStyle