import React from 'react'
import styled from 'styled-components'

const LoadingIcon = props => {

    return (
        <svg viewBox="0 0 32 32" focusable="false" role={props.role ? props.role : 'img'} height="32px" width="32px" aria-labelledby={props.id} className={props.className}>
            <title id={props.id}>{props.title ? props.title : 'Loading icon'}</title>
            <path d="M9.5,16c0-0.8-0.7-1.5-1.5-1.5H2.2c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H8C8.8,17.5,9.5,16.8,9.5,16z"/>
            <path d="M29.8,14.5h-2.9c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h2.9c0.8,0,1.5-0.7,1.5-1.5S30.6,14.5,29.8,14.5z"/>
            <path d="M16.7,8.7c0.8,0,1.5-0.7,1.5-1.5V1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v5.8
		C15.3,8.1,15.9,8.7,16.7,8.7z"/>
            <path d="M16.7,23.3c-0.8,0-1.5,0.7-1.5,1.5v5.8c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-5.8
		C18.2,23.9,17.5,23.3,16.7,23.3z"/>
            <path d="M7.5,4.7C6.9,4.1,6,4.1,5.4,4.7c-0.6,0.6-0.6,1.5,0,2.1l4.1,4.1c0.3,0.3,0.7,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4
		c0.6-0.6,0.6-1.5,0-2.1L7.5,4.7z"/>
            <path d="M23.9,21.1c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l4.1,4.1c0.3,0.3,0.7,0.4,1,0.4
		c0.4,0,0.7-0.1,1-0.4c0.6-0.6,0.6-1.5,0-2.1L23.9,21.1z"/>
            <path d="M9.5,21.1l-4.1,4.1c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4l4.1-4.1
		c0.6-0.6,0.6-1.5,0-2.1C11,20.6,10.1,20.6,9.5,21.1z"/>
        </svg>
    )
}

const LoadingIconStyle = styled(LoadingIcon)`
    fill: ${props => (props.fill ? props.fill : 'currentColor')};
    width: ${props => (props.width ? props.width : 'var(--s0)')};
    height: ${props => (props.height ? props.height : 'var(--s0)')};
    margin: ${props => (props.margin ? props.margin : '0')};
    animation: rotation 2s infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
`
export default LoadingIconStyle