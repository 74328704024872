import React from 'react'
import styled from 'styled-components'

const AlertIcon = props => {

    return (
        <svg viewBox="0 0 32 32" focusable="false" aria-hidden={props.ariaHidden ? 'true' : 'false'} role={props.role ? props.role : 'img'} height="32px" width="32px" aria-labelledby={props.id} className={props.className}>
            <title id={props.id}>{props.title ? props.title : 'Alert icon'}</title>
            <path d="M30.5 16C30.5 23.732 24.232 30 16.5 30C8.76801 30 2.5 23.732 2.5 16C2.5 8.26801 8.76801 2 16.5 2C24.232 2 30.5 8.26801 30.5 16Z" />
            <path fill="var(--color-white)" d="M16.5 8.875C17.0523 8.875 17.5 9.32272 17.5 9.875V18.625C17.5 19.1773 17.0523 19.625 16.5 19.625C15.9477 19.625 15.5 19.1773 15.5 18.625V9.875C15.5 9.32272 15.9477 8.875 16.5 8.875Z" />
            <path fill="var(--color-white)" d="M17.5 22.25C17.5 22.8023 17.0523 23.25 16.5 23.25C15.9477 23.25 15.5 22.8023 15.5 22.25C15.5 21.6977 15.9477 21.25 16.5 21.25C17.0523 21.25 17.5 21.6977 17.5 22.25Z" />
        </svg>
    )
}

const AlertIconStyle = styled(AlertIcon)`
    fill: ${props => (props.fill ? props.fill : 'currentColor')};
    width: ${props => (props.width ? props.width : 'var(--s0x1-5)')};
    height: ${props => (props.height ? props.height : 'var(--s0x1-5)')};
    margin: ${props => (props.margin ? props.margin : '0')};
`

export default AlertIconStyle
