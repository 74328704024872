import React from 'react'
import styled from 'styled-components'
import UnorderedList from '../UnorderedList/UnorderedList'

const ErrorSummary = props => {

    let errorVisible = ''
    let errorSummaryDisplay = ''

    if(props.errors && props.formSubmitted && props.summary) {
        errorVisible = 'error-visible'
        errorSummaryDisplay = (
            <div>
                <PStyle><strong>Please correct errors with the following fields</strong></PStyle>
                <UnorderedList className="bullet-lists" borderColor="var(--color-error)">
                    {props.errors.map(error => (
                        <li key={error.formId}><a href={'#' + error.formId}>{error.errorLabel}</a></li>
                        ))
                    }
                </UnorderedList>
            </div>
        )
    }

    return(
        <div aria-live="polite" id="error-summary" tabIndex="-1" className={`${props.className} ${errorVisible}`}>
            {errorSummaryDisplay}
        </div>
    )
}

const ErrorSummaryStyle = styled(ErrorSummary)`
  
  
  & > div {
      display: ${props => (props.errors && props.formSubmitted && props.summary ? 'block' : 'none')};
      border:var(--border-thinnest) solid var(--color-error-border);
      background:var(--color-error-background);
      border-radius:var(--border-radius);
      color:var(--color-error);
      padding:var(--s0);
      margin-bottom:var(--s2);
  }
  
  
  & + * {
    margin-top:0;
  }
  
  &.error-visible {
    display:block;
  }
  
  li a {
    color:var(--color-error);
  }
  && li::before {
      background-color:var(--color-error);
      border: calc(0.75*var(--s-5)) solid var(--color-error);
  }
`
const PStyle = styled.p`
  padding-bottom:var(--s-2);
`

export default ErrorSummaryStyle