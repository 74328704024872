import React from 'react'
import styled from 'styled-components'

const StackSmall = props => (
    <div className={props.className}>
        {props.children}
    </div>
)

const StackSmallContainer = styled(StackSmall)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
     
    > * {
        margin-top: 0;
        margin-bottom: 0;
    }
    &.first-margin > * {
        margin-top: var(--s0);
    }
    & > * + * {
        margin-top: var(--s0);
    }
`

export default StackSmallContainer