import React from 'react'
import styled from 'styled-components'
import LoadingIcon from '../Icons/LoadingIcon'

const Button = props => {

    const { loadingState, loadingText, ...childProps } = props
    let disabledState =''
    if(props.loadingState) {
        disabledState = props.loadingState ? 'disabled' : ''
    }
    if(props.disabled) {
        disabledState = props.disabled ? 'disabled' : ''
    }

    return (
        <button {...childProps} disabled={disabledState} className={props.className}>
            {props.loadingState ? <LoadingIcon margin="0 var(--s-4) 0 0" /> : ''}
            {props.loadingState && props.loadingText ? props.loadingText : props.children}
        </button>
    )
}

const ButtonBase = styled(Button)`
    display:inline-flex;
    font-size:var(--s0);
    padding: ${props => (props.padding ? props.padding : 'var(--s-3) var(--s2)')};
    text-decoration:none;
    font-family: inherit;
    align-items: center;
    justify-content: center;
    align-self: start;
    border-radius: var(--border-radius);
    min-width: 10ch;
    text-align: center;
    transition: ${props => (props.transition ? props.transition : '220ms all ease-in-out')};
    width: ${props => (props.$fullWidth ? '100%' : 'auto')};
    cursor: pointer;


    @media screen and (-ms-high-contrast: active) {
        border: var(--border-thinnest) solid currentcolor;
    }

    &:disabled {
        opacity:.6;

        &:hover, &:active {
            cursor:not-allowed
        }
    }

    &.button-large {
        font-size:var(--s1);
    }

    &.button-small {
        font-size:var(--font-size-small);
    }
    
    * + svg {
        margin-left:var(--s-2);
    }
`

export const ButtonPrimary = styled(ButtonBase)`
    && {
        background-color: var(--color-primary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-primary-border)')};
        color: var(--color-text-light);
    }

    & :hover {
        background-color: var(--color-primary-background-hover);
        border: var(--border-thin) solid var(--color-primary-border-hover);
        //box-shadow: var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }

    & :active {
        border: var(--border-thin) solid var(--color-primary-border);
        //box-shadow: inset var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }
    
`

export const ButtonSecondary = styled(ButtonBase)`
    && {
        background-color: var(--color-secondary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-secondary-border)')};
        color: var(--color-text-light);
    }

    & :hover {
        border: var(--border-thin) solid var(--color-secondary-border-hover);
        //box-shadow: var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }

    & :active {
        border: var(--border-thin) solid var(--color-secondary-background);
        //box-shadow: inset var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }
    
`

export const ButtonTertiary = styled(ButtonBase)`
    && {
        background-color: var(--color-tertiary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-tertiary-border)')};
        color: var(--color-text-light);

    }

    & :hover {
        border: var(--border-thin) solid var(--color-tertiary-background);
    }

    & :active {
        border: var(--border-thin) solid var(--color-tertiary-background);
    }
    
`

export const ButtonPrimaryInverse = styled(ButtonBase)`
    && {
        background-color: var(--color-background-light-primary);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-primary-background)')};
        color: var(--color-primary);
    }

    & :hover {
        background-color: var(--color-background-light-secondary);
        //border: var(--border-thin) solid var(--color-primary-border-hover);
        //box-shadow: var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }

    & :active {
        border: var(--border-thin) solid var(--color-primary-border);
        //box-shadow: inset var(--border-thinnest) var(--border-thinnest) var(--border-thinnest) 0 rgba(92,92,92,1);
    }
    
`

export const ButtonIcon = styled(ButtonBase)`
    && {
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
        min-width: 0;
        color: ${props => (props.color ? props.color : 'inherit')};
    }
`

export const ButtonIconPrimary = styled(ButtonBase)`
    && {
        background-color: var(--color-primary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-primary-border)')};
        color: var(--color-text-light);
        box-shadow: none;
        padding: var(--s-3) var(--s0);
        min-width: 0;
    }
`

export const ButtonIconSecondary = styled(ButtonBase)`
    && {
        background-color: var(--color-secondary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-secondary-border)')};
        color: var(--color-text-light);
        box-shadow: none;
        padding: var(--s-3) var(--s0);
        min-width: 0;
    }
`

export const ButtonIconTertiary = styled(ButtonBase)`
    && {
        background-color: var(--color-tertiary-background);
        border: ${props => (props.border ? props.border : 'var(--border-thin) solid var(--color-tertiary-border)')};
        color: var(--color-text-light);
        box-shadow: none;
        padding: var(--s-3) var(--s0);
        min-width: 0;
    }
`

export const ButtonNoStyle = styled(Button)`
    border: none;
    background-color: transparent;
    font-family: inherit;
    padding: 0;
    cursor: pointer;
    color: ${props => (props.color ? props.color : 'var(--color-text-dark)')};
    text-decoration: ${props => (props.textDecoration ? props.textDecoration : 'underline')};

    * + svg {
        margin-left:var(--s-4);
    }
`