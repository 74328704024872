import React, { useState } from 'react'
import {graphql, navigate} from 'gatsby'
import LayoutContent from "../../components/Layout/LayoutContent"
import ContentDetails from "../../../../parsley-core/src/components/ContentDetail/ContentDetail"
import FormControl from 'parsley-core/src/components/FormControls/FormControls'
import { ButtonPrimary } from 'parsley-core/src/components/Button/Button'
import { checkValidity } from 'parsley-core/src/components/Utility/Utility'
import ErrorSummary from 'parsley-core/src/components/ErrorSummary/ErrorSummary'
import Message from 'parsley-core/src/components/Message/Message'
import Sidebar from "parsley-core/src/components/Sidebar/Sidebar"
import Box from "parsley-core/src/components/Box/Box"
import ImageContainer from "../../components/ImageContainer/ImageContainer"
import axios from 'axios'

const initialFormValues = {
    contactForm: {
        name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                id: 'name',
                autoComplete: 'name',
                'aria-describedby': 'error-name',
            },
            value: '',
            labelText: 'Your name',
            validation: {
                required: true
            },
            errorMessage: 'Your name is a required field',
            valid: false,
        },
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                id: 'email',
                autoComplete: 'email',
                'aria-describedby': 'error-email',
            },
            value: '',
            labelText: 'Your email address',
            validation: {
                required: true,
                isEmail: true
            },
            errorMessage: 'Email address is a required field and must follow the standard email format',
            valid: false,
        },
        message: {
            elementType: 'textarea',
            elementConfig: {
                id: 'message',
                'aria-describedby': 'error-message',
            },
            value: '',
            labelText: 'Your message',
            validation: {
                required: true,
            },
            errorMessage: 'Message is a required field',
            valid: false,
        },
    }
}

const Index = ({data}) => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [summary, setSummary] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const [sentStatus, setSentStatus] = useState(false)
    const [sentErrorStatus, setSentErrorStatus] = useState(false)
    const [formValues, setFormValues] = useState(initialFormValues)
    const pageTitle = 'Contact'
    const contactImage = data.contactImage

    const handleFormSubmit = event => {
        event.preventDefault()
        setFormLoading(true)
        setFormSubmitted(true)
        setSentStatus(false)
        setSentErrorStatus(false)
        document.querySelector("#error-summary").focus()

        const submittedContactForm = {
            ...formValues.contactForm
        }

        let noErrors = true
        for (let key in submittedContactForm) {
            submittedContactForm[key].valid = checkValidity(submittedContactForm[key].value, submittedContactForm[key].validation)
            if(submittedContactForm[key].valid === false) noErrors = false
        }
        setFormValues({contactForm: submittedContactForm})

        if(noErrors === true) {

            const formData = {}
            for (let formElementIdentifier in formValues.contactForm) {
                formData[formElementIdentifier] = formValues.contactForm[formElementIdentifier].value
            }

            const dataToSend = JSON.stringify(formData)
            axios.post( 'https://www.drmatthewbeck.com.au/mail-api.php', dataToSend )
                .then(response => {
                    if(response.status === 200) {
                        setFormLoading(false)
                        setFormSubmitted(false)
                        setSentStatus(true)
                        setFormValues({...initialFormValues})
                        navigate('/contact', { state: {sentStatus}})
                        document.querySelector("#contact-message").focus()
                    }
                } )
                .catch( error => {
                    setFormLoading(false)
                    setSentErrorStatus(true)
                    navigate('/contact', { state: {sentErrorStatus}})
                    document.querySelector("#contact-message").focus()
                } )
            setSummary(false)
        } else {
            setSummary(true)
            setFormLoading(false)
        }
    }

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedContactForm = {
            ...formValues.contactForm
        }
        const updatedFormElement = {
            ...updatedContactForm[inputIdentifier]
        }
        updatedFormElement.value = event.target.value
        updatedContactForm[inputIdentifier] = updatedFormElement
        setFormValues({contactForm: updatedContactForm})
    }

    let messageDisplay = ''
    if(sentStatus) messageDisplay = (<Message aria-live="polite" messageId="contact-message" className="success space-below" message="The email was successfully sent" display={sentStatus} />)
    if(sentErrorStatus) messageDisplay = (<Message aria-live="polite" messageId="contact-message" className="error space-below" message="There was an error and the message was not sent, try again." display={sentErrorStatus} />)

    const formElementsArray = []
    const formErrorsArray = []
    for (let key in formValues.contactForm) {
        formElementsArray.push({
            id: key,
            config: formValues.contactForm[key]
        })

        if(formValues.contactForm[key].valid === false) {
            formErrorsArray.push({
                errorLabel: formValues.contactForm[key].labelText,
                formId: formValues.contactForm[key].elementConfig.id
            })
        }
    }
    let errorDisplay = (
        <ErrorSummary errors={formErrorsArray} formSubmitted={formSubmitted} summary={summary} />
    )

    let form = (
        <form onSubmit={handleFormSubmit}>
            <ContentDetails className="first-margin">
                {formElementsArray.map(formElement => (
                <FormControl
                    key={formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    invalid={!formElement.config.valid}
                    formSubmitted={formSubmitted}
                    shouldValidate={formElement.config.validation}
                    errorMessage={formElement.config.errorMessage}
                    changed={(event) => inputChangedHandler(event, formElement.id)}
                    labelText={formElement.config.labelText}
                    background="--color-background-light-primary"/>
                ))}
                <ButtonPrimary loadingState={formLoading} type="submit">Send</ButtonPrimary>
            </ContentDetails>
        </form>
    )

    return (
        <LayoutContent title={pageTitle}>
            <Sidebar side flexBasis="40ch" lastChildWidth="60%" marginCalculation="calc(10*var(--s0))">
                <div>
                    <div>
                        {messageDisplay}
                        {errorDisplay}
                        <p>To contact Dr Matthew Beck call 02 4228 1088 or use the following contact form.</p>
                        {form}
                    </div>
                    <div>
                        <Box background="var(--color-tertiary-background)" borderRadius="var(--border-medium)" padding="var(--s1)">
                            <ContentDetails>
                                <h2 className="h3">Hours of business</h2>
                                <ul>
                                    <li>Monday - Friday 9am to 5pm</li>
                                </ul>
                                <ImageContainer image={contactImage.childrenFile[0].childImageSharp.gatsbyImageData} alt="Photo of medical practice of Dr Matthew Beck" />
                            </ContentDetails>
                        </Box>
                    </div>
                </div>
            </Sidebar>
        </LayoutContent>

    )
}



export default Index

export const pageQuery = graphql`  
  query {
  contactImage: mdx(fileAbsolutePath: {regex: "/contact.mdx/"}) {
    childrenFile {
      childImageSharp {
        gatsbyImageData(
        	breakpoints: [300, 369]
			layout: CONSTRAINED
			quality: 50
			width: 369
		)
      }
    }
  }
}
`